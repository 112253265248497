import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../ContextAPI/GlobalState';
import { Link } from 'react-router-dom';
import './Profile.css';

export const ProfileView = () => {
  const { contract, account } = useContext(GlobalContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [Logo, setLogo] = useState('');

  useEffect(() => {
    (async () => {
      if (contract) {
        const {
          name,
          email,
          logo,
          description,
        } = await contract.methods.getAccount().call({ from: account });

        setName(name);
        setEmail(email);
        setDescription(description);
        setLogo(logo);
      }
    })();
  }, [contract, account]);

  return (
    <div className='profileViewBG'>
      <div className='container-fluid pt-5 text-center profileViewBGMain '>
        <fieldset className='p-4 '>
          <legend className='w-auto'>
            {!Logo ? (
              <i className='fas fa-user-circle mainIcon'></i>
            ) : (
              <img
                className='img-fluid pImg'
                src={`https://ipfs.io/ipfs/${Logo}`}
                alt='img'
              />
            )}
          </legend>

          {!name ? (
            <h1 className='name'>N/A</h1>
          ) : (
            <h1 className='name'>{name}</h1>
          )}

          <div className='socialIcons'>
            <i className='fab fa-facebook-f'></i>
            <i className='fab fa-twitter'></i>
            <i className='fab fa-youtube'></i>
            <i className='fab fa-linkedin-in'></i>
            <i className='fab fa-github'></i>
          </div>

          <div className='text-left'>
            {!email ? <p>Email: N/A</p> : <p>Email: {email}</p>}
            {!description ? <p>Bio: N/A</p> : <p>Bio: {description}</p>}
          </div>

          <Link to='/profile' className='btn btn-warning'>
            Edit Profile
          </Link>
        </fieldset>
      </div>
    </div>
  );
};
