import React from 'react';
import Loader1 from '../../assets/images/loader.gif';
import Loader2 from '../../assets/images/loading.gif';
import './Loader.css';

export const Loader = () => {
  return (
    <div className='container text-center'>
      <img alt='loader' className='loader' src={Loader1} />
    </div>
  );
};

export const Load02 = () => {
  return (
    <div className='container text-center'>
      <img alt='loader' className='loader' src={Loader2} />
    </div>
  );
};
