import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../ContextAPI/GlobalState';
import { Loader } from '../index';

export const AddAuthUser = () => {
  const { addAuthUser, loading } = useContext(GlobalContext);
  const [address, setaddress] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      (address.length > 1 && address.substr(0, 2) !== '0x') ||
      address.length !== 42
    ) {
      alert('Please enter a valid ETH address');
      //   $('.alert').addClass('show');
    } else {
      addAuthUser(address);
    }
  };

  if (loading === true) {
    return <Loader />;
  } else {
    return (
      <div className='loginBg'>
        <div className='container-fluid pt-5 text-center main '>
          <fieldset className='p-4'>
            <legend className='w-auto'>
              <i className='fas fa-user-plus mainIcon'></i>
            </legend>

            <p className='py-3'>Add Authorized User</p>

            <form className='pb-5' onSubmit={onSubmit}>
              <div className='form-group'>
                <input
                  type='text'
                  value={address}
                  required
                  onChange={(e) => setaddress(e.target.value)}
                  className='form-control'
                  id='docAddressFormControlSelect'
                  placeholder='Enter Wallet Address'
                />
              </div>
              <button type='submit' className='btn btn-block btn-warning'>
                Add Authorized User <i className='fas fa-user-plus '></i>
              </button>
            </form>
            <p>Design By EdVeri</p>
          </fieldset>
        </div>
      </div>
    );
  }
};
