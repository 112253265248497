import React, { createContext, useEffect, useReducer } from 'react';
import { jsPDF } from 'jspdf';
import AppReducer from './AppReducer';
import Web3 from 'web3';
import DocVerification from '../contracts/DocVerification.json';

const initialState = {
  web3: null,
  loading: false,
  isLogin: false,
  account: '',
  contract: null,
};

// create Global Context
export const GlobalContext = createContext(initialState);

// create context provider
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    const loadBlockChainData = async () => {
      try {
        const web3 = new Web3(Web3.givenProvider);
        await Web3.givenProvider.enable();
        setWeb3(web3);

        // Get the contract instance.
        const networkId = await web3.eth.net.getId();

        const { address } = await DocVerification.networks[networkId];
        const contract = new web3.eth.Contract(DocVerification.abi, address);
        setContract(contract);
      } catch (error) {
        alert(
          'Error in loading web3 please use a dapp supported browser & ethereum wallet'
        );
      }
    };
    loadBlockChainData();
  }, []);

  async function addUserProfile({ name, email, description, logo }) {
    const { contract, account } = state;
    await contract.methods
      .register(name, email, logo, description)
      .send({ from: account }, (err, txHash) => {
        console.log(err);
        if (err) {
          setLoading(false);
          alert(
            'Transaction Fail! Please Check your metamask Connection and Balance!'
          );
        } else {
          setLoading(false);
        }
        console.log('Tx Hash', txHash);
        // console.log('this is Error', err);
      });
  }

  async function addDocument({ _authName, _docTitle, _docAddress, _stdID }) {
    const { contract, account } = state;

    const receipt = await contract.methods
      .addDocument(_authName, _docTitle, _docAddress, _stdID)
      .send({ from: account }, (err, txHash) => {
        console.log(err);
        if (err) {
          setLoading(false);
          alert(
            'Transaction Fail! Please Check your metamask Connection and Balance!'
          );
        } else {
          setLoading(false);
        }
        console.log('Tx Hash', txHash);
        // console.log('this is Error', err);
      });

    // Destructure Tx Hash
    const { transactionHash } = receipt;
    console.log('Transaction Hash', transactionHash);

    if (transactionHash) {
      generatePDF();
    }

    async function generatePDF() {
      var doc = new jsPDF('landscape');
      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc.setTextColor(197, 48, 39);
      doc.setFontSize(35);
      doc.setFont('courier');
      // doc.setFontType('bold');

      // Main Title
      doc.text(
        'Document Verification System',
        pageWidth / 2,
        pageHeight - 180,
        'center'
      );
      doc.setFontSize(25);
      doc.text('EdVeri', pageWidth / 2, pageHeight - 165, 'center');

      doc.setFontSize(14);

      doc.setTextColor(64, 61, 61);
      doc.text('Auth Name: ', 15, 70);

      doc.text(_authName, 65, 70);

      doc.text('Doc Title: ', 15, 80);

      doc.text(_docTitle, 65, 80);

      doc.text('Doc Status: ', 15, 90);

      doc.text('Verify', 65, 90);

      doc.text('Ipfs Hash: ', 15, 100);

      doc.text(_docAddress, 65, 100);

      doc.text('Tx Hash: ', 15, 110);

      doc.text('Student ID: ', 15, 120);

      doc.text(_stdID, 65, 120);

      doc.text(transactionHash, 65, 110);
      doc.setFontSize(35);
      doc.text('THANK YOU', pageWidth / 2, pageHeight - 50, 'center');

      // FOOTER
      let str = 'Powerd by - www.edveri.surge.sh';
      doc.setTextColor(100);
      doc.setFontSize(16);
      doc.text(str, pageWidth / 2, pageHeight - 10, 'center');
      doc.save(_docAddress);
    }
  }

  async function addAuthUser(user) {
    setLoading(true);
    const { contract, account } = state;
    await contract.methods
      .addToValidUser(user)
      .send({ from: account }, (err, txHash) => {
        console.log(err);
        if (err) {
          setLoading(false);
          alert(
            'Transaction Fail! Please Check your metamask Connection and Balance!'
          );
        } else {
          setLoading(false);
        }
        console.log('Tx Hash', txHash);
        // console.log('this is Error', err);
      });
  }

  function isLoginUser({ acc, islogin }) {
    setIslogin(islogin);
    setAccount(acc);
  }

  function setWeb3(web3) {
    dispatch({
      type: 'SET_WEB3',
      payload: web3,
    });
  }

  function setIslogin(isLogin) {
    dispatch({
      type: 'SET_LOGIN',
      payload: isLogin,
    });
  }

  function setAccount(account) {
    dispatch({
      type: 'SET_ACCOUNT',
      payload: account,
    });
  }

  function setContract(contract) {
    dispatch({
      type: 'SET_CONTRACT',
      payload: contract,
    });
  }

  function setLoading(loading) {
    dispatch({
      type: 'SET_LOADING',
      payload: loading,
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        loading: state.loading,
        web3: state.web3,
        isLogin: state.isLogin,
        isLoginUser,
        addUserProfile,
        addDocument,
        addAuthUser,
        setLoading,
        contract: state.contract,
        account: state.account,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
