import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../ContextAPI/GlobalState';

import './Header.css';

function Header() {
  const { isLogin, isLoginUser, account, contract } = useContext(GlobalContext);

  const [owner, setOwner] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    (async () => {
      if (account) {
        const isOwner = (await contract.methods.owner().call()).toLowerCase();
        if (account.toLowerCase() === isOwner) {
          setOwner(true);
        } else {
          setOwner(false);
        }
        const isValid = await contract.methods.isValiduser(account).call();
        setIsValid(isValid);
      }
    })();
  }, [account, contract]);

  const userClickHandle = () => {
    const setUserStatus = {
      acc: null,
      islogin: false,
    };
    isLoginUser(setUserStatus);
  };

  return (
    <div>
      <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
        <div className='container'>
          <Link to='/' className='navbar-brand' href='#'>
            <img src='./images/logo01.png' height='35' alt='' loading='lazy' />
          </Link>

          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            {isLogin === false ? (
              <ul className='navbar-nav ml-auto text-center'>
                <li className='nav-item active'>
                  <Link to='/' className='nav-link'>
                    <i className='fas fa-home'></i> Home
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link to='/about' className='nav-link'>
                    <i className='fas fa-info-circle'></i> About
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/login' className='nav-link'>
                    <i className='fas fa-sign-in-alt'></i> Login
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className='navbar-nav ml-auto text-center'>
                {isValid === true ? (
                  <li className='nav-item active'>
                    <Link to='/dashboard' className='nav-link'>
                      <i className='fas fa-stream'></i> Dashboard
                    </Link>
                  </li>
                ) : (
                  ''
                )}

                <li className='nav-item'>
                  <Link to='/readdoc' className='nav-link'>
                    <i className='fas fa-search'></i> Search Doc
                  </Link>
                </li>

                {owner === true ? (
                  <li className='nav-item'>
                    <Link to='/addAuthUser' className='nav-link'>
                      <i className='fas fa-user-plus'></i> Add User
                    </Link>
                  </li>
                ) : (
                  ''
                )}

                <li className='nav-item'>
                  <Link to='/profileView' className='nav-link'>
                    <i className='fas fa-user-tie'></i> Profile
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link
                    to='/login'
                    onClick={userClickHandle}
                    className='nav-link'
                  >
                    <i className='fas fa-sign-out-alt'></i> Logout
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
