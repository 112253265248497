import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { GlobalContext } from '../../ContextAPI/GlobalState';
import {
  About,
  Home,
  Login,
  Profile,
  Dashboard,
  ReadDoc,
  Error404,
  ProfileView,
  AddAuthUser,
} from '../../components';

function AppRoutes() {
  const { isLogin } = useContext(GlobalContext);

  return (
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/Login' element={<Login />} />
      <Route exact path='/about' element={<About />} />
      {isLogin === true ? (
        <>
          <Route exact path='/dashboard' element={<Dashboard />} />
          <Route exact path='/profile' element={<Profile />} />
          <Route exact path='/readdoc' element={<ReadDoc />} />
          <Route exact path='/profileView' element={<ProfileView />} />
          <Route exact path='/addAuthUser' element={<AddAuthUser />} />
        </>
      ) : (
        'false'
      )}
      <Route path='*' element={<Error404 />} />
    </Routes>
  );
}

export default AppRoutes;
