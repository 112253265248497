import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../ContextAPI/GlobalState';
import { Link } from 'react-router-dom';
import './Login.css';

function Login() {
  const { web3, isLoginUser } = useContext(GlobalContext);
  const [Account, setAccount] = useState('');
  const [rout, setRout] = useState('/login');

  useEffect(() => {
    (async () => {
      if (web3) {
        const account = await web3.eth.getCoinbase();

        setAccount(account);
        setRout('/profileView');
      }
    })();
  }, [web3]);

  const userClickHandle = () => {
    if (Account) {
      const setUserStatus = {
        acc: Account,
        islogin: true,
      };
      isLoginUser(setUserStatus);
    } else {
      alert('Please install Metamask Extention! or use dapp supported browser');
    }
  };

  return (
    <div className='loginBg'>
      <div className='container-fluid pt-5 text-center main '>
        <fieldset className='p-4'>
          <legend className='w-auto'>
            <img
              className='img-fluid pImg'
              src='/images/edveriWhite.png'
              alt='img'
            />

            {/* <i className='fas fa-user-secret mainIcon'></i> */}
          </legend>
          <h1 className='loginHeading px-3'>Login</h1>
          <p className='py-3'>
            Welcome to Documents Verification System Blockchain Project
          </p>

          <form className='pb-5'>
            <div className='form-group'>
              <input
                type='text'
                readOnly
                value={Account}
                className='form-control'
                id='docAddressFormControlSelect'
                placeholder='Connect Metamask'
              />
            </div>

            <Link
              to={rout}
              onClick={userClickHandle}
              className='btn btn-warning btn-block'
            >
              <i className='fas fa-sign-in-alt'></i> Login
            </Link>
          </form>
          <p>Design By EdVeri</p>
        </fieldset>
      </div>
    </div>
  );
}

export default Login;
