import React from 'react';

import './About.css';

function About() {
  return (
    <div className='loginBg'>
      <div className='container pt-5 text-center '>
        <fieldset className='p-4'>
          <legend className='w-auto'>
            <h1 className='px-4 display-3 pt-5'>ABOUT US</h1>
          </legend>
          <h4>
            Having this idea for developing Dapp on blockchain can make sure
            that nobody will edit the digital document (Student credential) once
            it's been uploaded by the institute on blockchain network. It’s sort
            of surety from the university or an institute.
            <br /> <br />
            The plan is to produce a system to any entity to upload its
            documents to blockchain and additionally yield live read of all
            transactions and since all documents will currently be uploaded
            digitally to the blockchain through the system then physical work
            will be eliminated. An idea behind this solution is that once you
            start using a digital notary for verification with time stamping on
            an open ledger it can never be tampered, or alter hence permitting
            complete transparency and view to all parties.
          </h4>
        </fieldset>
      </div>
    </div>
  );
}

export default About;
