import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../ContextAPI/GlobalState';
import { Loader } from '../index';
import ipfs from '../../ContextAPI/IPFS_Config';

import './Profile.css';

export const Profile = () => {
  const { addUserProfile, setLoading, loading } = useContext(GlobalContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');

  let imgBuffer = null;
  let hashIPFS = null;

  const onchange = (event) => {
    const file = event.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      imgBuffer = Buffer(reader.result);
      console.log(imgBuffer);
    };
  };

  async function addData() {
    await ipfs.files.add(imgBuffer, (err, result) => {
      if (err) {
        console.error(err);
        alert('Please select correct image again!');
        setLoading(false);
        return;
      }
      hashIPFS = result[0].hash;

      const addUser = {
        name: name,
        email: email,
        description: description,
        logo: hashIPFS,
      };
      addUserProfile(addUser);
      console.log('Success', result[0].hash);
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    addData();
  };

  if (loading === true) {
    return <Loader />;
  } else {
    return (
      <div className='loginBg'>
        <div className='container-fluid pt-5 text-center main '>
          <fieldset className='p-4 '>
            <legend className='w-auto'>
              <i className='fas fa-user-circle mainIcon'></i>
            </legend>

            <h1 className='name py-4'>Add User Profile</h1>

            <form onSubmit={onSubmit}>
              <div className='form-group'>
                <input
                  type='text'
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                  className='form-control'
                  placeholder='full name'
                />
              </div>
              <div className='form-group'>
                <input
                  type='email'
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className='form-control'
                  placeholder='email'
                />
              </div>
              <div className='form-group'>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className='form-control'
                  placeholder='bio'
                  required
                  rows='3'
                ></textarea>
              </div>

              <div className='form-group'>
                <input
                  type='file'
                  required
                  accept='.png, .jpg, .jpeg'
                  className='form-control-file'
                  onChange={onchange}
                />
              </div>
              <button type='submit' className='btn btn-warning btn-block'>
                Add User Profile
                <i className='fas fa-user-plus'></i>
              </button>
            </form>
          </fieldset>
        </div>
      </div>
    );
  }
};
