import React from 'react';
import { Link } from 'react-router-dom';
import './Error404.css';

export const Error404 = () => {
  return (
    <div className='errorPage'>
      <div className='container   text-center py-5'>
        <div className='card'>
          <div className='card-body'>
            <img alt='404' className='errImg' src='./images/404.png' />
            {/* <h1 className='err'>404.</h1> */}
            <h1>Oops! Page not found</h1>

            <h3>
              Sorry, the page you're looking for doesn't exist. if you think
              someting is broken, report a problem.
            </h3>
            <Link to='/' className='btn btn-danger  '>
              Return Home
            </Link>

            <a href='mailto: abc@example.com' className='btn btn-danger '>
              Report Problem
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
