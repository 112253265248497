import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../ContextAPI/GlobalState';
import { Loader } from '../index';

import './ReadDoc.css';

export const ReadDoc = () => {
  const { contract, setLoading, loading } = useContext(GlobalContext);
  const [Id, setId] = useState('');

  const [docId, setdocId] = useState('');
  const [name, setName] = useState('');
  const [verAdd, setVerAdd] = useState('');
  const [docTit, setDocTit] = useState('');
  const [alert, setAlert] = useState(false);

  const onsubmit = (e) => {
    e.preventDefault();
    getDoc();

    async function getDoc() {
      setLoading(true);
      const {
        _authName,
        _verifier,
        _docTitle,
        _docAddress,
      } = await contract.methods.getDocument(Id).call((err) => {
        if (err) {
          console.log('there is some error to load data:', err);
          alert(
            'there is some error to load data: please check metamask connection!'
          );
          setLoading(false);
        }
      });

      setName(_authName);
      setVerAdd(_verifier);
      setDocTit(_docTitle);
      setdocId(_docAddress);
      setLoading(false);
      if (!_authName) {
        setAlert(true);
      } else {
        setAlert(false);
      }
    }
  };

  if (loading === true) {
    return <Loader />;
  } else {
    return (
      <div className='loginBg'>
        <div className='container-fluid  text-center  '>
          <div className='container main'>
            <div className='row'>
              <div className='col-12 text-center'>
                <div className=''>
                  <fieldset className='p-4'>
                    <legend className='w-auto'>
                      <i className='fas fa-search mainIcon'></i>
                      {/* <i className='fas fa-user-secret mainIcon'></i> */}
                    </legend>
                    <h1 className='loginHeading px-3'>Search Document</h1>

                    <form onSubmit={onsubmit}>
                      <div className='form-group'>
                        <input
                          type='number'
                          value={Id}
                          required
                          onChange={(e) => setId(e.target.value)}
                          className='form-control'
                          placeholder='Student ID'
                        />
                      </div>

                      <button
                        type='submit'
                        className='btn btn-warning btn-block'
                      >
                        Search <i className='fas fa-search'></i>
                      </button>

                      {alert !== false ? (
                        <div className='mt-4'>
                          <div
                            className='alert alert-danger alert-dismissible fade show'
                            role='alert'
                          >
                            <strong>Student Not Found!</strong>

                            <button
                              type='button'
                              className='close'
                              data-dismiss='alert'
                              aria-label='Close'
                            >
                              <span aria-hidden='true'>&times;</span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </form>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          {name !== '' ? (
            <div className='container py-5 text-left'>
              <h1 className='loginHeading'>Document View</h1>

              <h5 className=''>Student Name: {name}</h5>
              <h5 className=''>Document Title: {docTit}</h5>
              <h5 className=''>
                Verifier Address: <span className='text-warning'>{verAdd}</span>
              </h5>

              {docId !== '' ? (
                <a
                  href={`https://ipfs.io/ipfs/${docId}`}
                  target='_blank'
                  className='text-warning'
                  rel='noopener noreferrer'
                >
                  View Document
                </a>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {docId !== '' ? (
            <img
              src={`https://ipfs.io/ipfs/${docId}`}
              alt='Document Img'
              className='img-fluid'
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
};
