import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../ContextAPI/GlobalState';
import './Home.css';
import banner from '../../assets/images/back.png';

function Home() {
  const { isLogin } = useContext(GlobalContext);

  return (
    <div className='home'>
      <div className='container'>
        <div className='pt-5'>
          <div className='row  py-5 text-white'>
            <div className='col-lg-4 col-12 align-self-center'>
              <h1 className='title'>Document Verification System</h1>
              <h2>Blockchain Project</h2>
              {isLogin === false ? (
                <Link
                  to='/login'
                  type='button'
                  className='btn btn-lg btn-warning btn-block'
                >
                  <i className='fas fa-sign-in-alt'></i> Login
                </Link>
              ) : (
                <Link
                  to='/dashboard'
                  type='button'
                  className='btn btn-lg btn-warning btn-block'
                >
                  <i className='fas fa-sign-in-alt'></i> Goto Dashboard
                </Link>
              )}
            </div>
            <div className='col-lg-8 col-12'>
              <img src={banner} alt='banner' className='img-fluid' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
